import React from 'react'
import { LandingContainer } from '.'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="Not done yet 😢" />
    <LandingContainer>
      <div className="vh-100 flex flex-column items-center justify-center">
        <section className="mb3 pa3">
          <h1>Ooops</h1>
        </section>
        <section className="mb3 ph3 pv2">
          <p>I haven't gotten to writing this part yet 😢</p>
        </section>
        <section className="pa3">
          <a href="/">Bring me back to safety</a>
        </section>
      </div>
    </LandingContainer>
  </>
)

export default NotFoundPage
